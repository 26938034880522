<template>
  <div>
    <!-- 你可能想要添加一些 UI 元素来显示退订状态 -->
  </div>
</template>

<script lang="ts" setup>
import { ref, getCurrentInstance, onMounted } from "vue";
import { useRoute } from 'vue-router';
import { ElMessage } from 'element-plus'

const route = useRoute();
const { proxy }: any = getCurrentInstance();

// 使用 ref 来存储 key 值
let routeKey = ref<string>('');

// methods
const init = () => {
  // 从路由参数中获取 key
  routeKey.value = route.params.key as string;

  if (route.query.unsubscribe !== undefined) {
    handleUnsubscribe();
  } else if (routeKey.value && routeKey.value !== '') {
    getUrl();
  }
};

const getUrl = () => {
  if (!routeKey.value) {
    console.warn('No key provided for URL retrieval');
    return;
  }
  proxy.$http.getUrl({ param: routeKey.value }).then((res: { code: number; data: any }) => {
    if (res.code == 200) {
      if (res.data.redirectURL) {
        window.open(res.data.redirectURL, '_self');
      }
    }
  }).catch((error: any) => {
    console.error('Error getting URL:', error);
    ElMessage({
      message: 'Failed to get URL',
      type: 'error',
    });
  });
};

const handleUnsubscribe = () => {
  const fullUrl = window.location.href;
  proxy.$http.unSubscribea({ url: fullUrl }).then((res: { code: number; data: any }) => {
    if (res.code == 200) {
      ElMessage({
        message: 'Successfully unsubscribed',
        type: 'success',
      });
    } else {
      ElMessage({
        message: 'Failed to unsubscribe',
        type: 'error',
      });
    }
  }).catch((error: any) => {
    console.error('Unsubscribe error:', error);
    ElMessage({
      message: 'An error occurred while unsubscribing',
      type: 'error',
    });
  });
};

// lifecycle
onMounted(() => {
  init();
});
</script>
