
import axios from 'axios'
// import { ElMessage } from 'element-plus'
const request = axios.create({
  timeout: 5000,
})
// 请求拦截器
request.interceptors.request.use((config) => {
//   config.headers.token = '123'
  return config
})
//响应拦截器
request.interceptors.response.use(
  (response) => {
    return response.data
  },
  (error) => {

    let msg
    let status = error.response.status
    switch (status) {
      // case 401:
      //   msg = 'token过期'
      //   break
      case 403:
        msg = 'Unauthorized access'
        break
      case 404:
        msg = 'Request address error'
        break
      case 500:
        msg = 'There is an issue with the server'
        break
      default:
        msg = 'No network'
    }
    // ElMessage({
    //   type: 'error',
    //   message: msg,
    // })
    return Promise.reject(error)
  },
)
export default request

