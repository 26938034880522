import base from './base'
import axios  from '@/utils/request'

const http ={
    getUrl:function(params:any){
        return axios.get(base.getUrl,{params})
    },
    unSubscribea:function (params:any){
        return axios.get(base.unsubscribea,{ params})
    }
}

export default http
