import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:key(.*)', // 使用通配符来匹配所有路径
    name: 'home',
    component: HomeView,
    props: true // 直接将路由参数作为 props 传递给组件
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
